footer {
    background-color: #EDE9DE;

}

.footer-section {
    padding: 1rem;
}

.footer-container {
    display: flex;
    max-width: 1400px;  /* Ajustez la valeur selon vos besoins */
    margin: 0 auto;
    width: 100%;
}

.footer-container>* {
    flex: 1;
}

.footer-logo img {
    display: block;
    max-width: 220px;
    margin: auto;
}

.footer-legal,
.footer-social {
    margin: auto;
}

.footer-legal p {
    text-align: center;
    font-size: 0.75rem;
    margin: 1.5rem 0;
}

.footer-legal a {
    color: #022521;
}

.footer-social {
    text-align: center;
}

.footer-social i {
    color: #1E312D;
    margin: 0 2rem;
    font-size: 400%;
}

/* Responsivness */
@media only screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column-reverse;
        align-items: center
    }

    .footer-social,
    .footer-legal {
        margin-top: 1.5rem !important;
    }
}