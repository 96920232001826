.section-bg {
  width: 100%;
}

.section-contenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.section-contenu>* {
  width: 50%;
  height: 400px;
  overflow: hidden;
  /* Masque les débordements */
}

.contenu-txt {
  padding: 1rem;
  display: flex;
  /* Active le mode flexbox */
  padding: 1rem 2rem;
  /* Ajoute un espacement horizontal (droite/gauche) */

}

.contenu-txt ul {
  list-style-type: disc;
  /* Enlève les puces */

  margin: 1.4rem 0;
}

.contenu-txt li {
  margin-bottom: 10px;
  /* Espacement entre les éléments de la liste */
}

.contenu-paragraphe {
  margin: auto;
  /* Centre verticalement l'élément */
}

.contenu-img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contenu-img img {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  padding: 1rem;
}