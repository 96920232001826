.button {
    background-color: #022521;
    color: #EDE9DE;
    padding: 0.5rem 1rem;
    border: solid #022521;
    border-radius: 80px;
    cursor: pointer;
    transition: all 0.5s ease-out;
    width: 300px;
    font-size: 1rem;
    margin: 1.4rem auto;
    display: block;

}

.button:hover {
    background-color: #EDE9DE;
    color: #022521;
}

.text-typeform {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .button {
        font-size: 0.85rem;
        width: 240px;
    }
}