h3 {
    font-weight: normal;
  }

.contact-text {
    margin: auto;
}

.icon-text {
    display: flex;
    gap:1rem;
}

.icon-text i {
    margin: auto 0;
    font-size: 1.3rem;
}

.icon-text p {
    font-size: 1rem;
    text-align: left;
    margin :0.5rem;
}

.section-contact {
    max-width: 700px;
    margin: auto;
    padding: 1rem;
    /* Largeur pour que 2 carrés tiennent par ligne (100% / 2 - marge) */
    /* Ajoute une bordure */

}

.section-bordure {
    
    border-radius: 8px;
    border: solid 3px ;
    box-sizing: border-box;
    padding: 1rem;
}

@media only screen and (max-width: 768px) {
    .icon-text i {
        font-size: 1rem;
    }

    .icon-text p {
        font-size: 0.80rem;
    }
}