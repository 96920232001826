@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');

@font-face {
    font-family: 'Krona_One';
    src: url("./assets/fonts/KronaOne-Regular.ttf");
    font-display: swap;
}

body {
    font-family: 'Krona_One';
    font-size: 0.8rem;
    background-color: #EDE9DE;
    color: #022521;
    padding: 0;
}

a {
    text-decoration: none;
}

h1,
h2,
h3 {
    text-align: center;
    margin: 1rem 0;
}

p {
    margin: 1.4rem 0;
    text-align: justify;
    hyphens: auto;
}

li {
    text-align: justify;
}

.section-bg {
    background-color: #EDE9DE;
    color: #022521;
}

.section-bg-inverse {
    background-color: #022521;
    color:  #EDE9DE;
}

.section {
    max-width: 900px;
    margin: auto;
    padding: 1rem;
}

.container {
    display: flex;
    gap: 2.5rem;
}

.gallery {
    margin: 2rem auto;
}

/* Responsivness */
@media only screen and (max-width: 768px) {

    .container {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    p {
        font-size: 0.75rem;
    }
}